.header {
  text-align: right;
  width: 90%;
  margin: 10px auto 5px auto;
  height: 5vh;
}

@media (min-width: 768px) {
  .file-reader .header {
    width: 90%;
  }
}
